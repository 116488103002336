import React from "react";
// import Fade from "react-reveal/Fade";

import "./banner.css";
const Banner = ({ head, subHead, bg }) => {
  return (
    <React.Fragment>
      <div
        style={{
          background: `radial-gradient(circle, rgba(60, 60, 60, 0.35) 0%, rgba(60, 60, 60, 0.35) 0%, rgba(60, 60, 60, 0.35) 0%), url('${bg}')center/cover no-repeat`,
        }}
        className="banner-image"
      >
        <div className="container">
         
            <div style={{ marginLeft: "20%" }}>
              <h1 className="text-[30px] font-semibold ">{head}</h1>

              <div
                elevation={0}
                style={{
                  width: 100,
                  height: 13,
                  background: "rgba(247, 148, 29, 0.75)",
                  marginTop: -20,
                  marginLeft: -20,
                }}
              />
            </div>
       
        </div>
      </div>
    </React.Fragment>
  );
};

export default Banner;

import React from "react";
import sendBtn from "../assets/send.png";
// import CustomizedSnackbars from "./Snackbar";
import styles from "../css/style.module.css";
function FooterInputControlled({ bg = false }) {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [message, setMessage] = React.useState(false);

  function validateEmail(emailAddress){
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(emailAddress).toLowerCase());
  }
  const handleSubscribe = () => {
      const result = validateEmail(email);
    if (!email) {
      setOpen(true);
      setStatus("error");
      setMessage("Please email must not  be empty");
    }else if(email && !result){
        setOpen(true);
        setStatus("error");
        setMessage("Please enter a valid email");
    }else{
        setOpen(true);
        setStatus("success");
        setMessage("Thanks for subscribing to our news letter");
        setEmail("")
    }
    
    console.log(result);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setEmail(e.target.value);
  };
  return (
    <div>
      <div
        className={!bg ? styles.InputContainer : styles.InputContainerBlueBG}
      >
        <input
          type="text"
          placeholder="Enter your Email"
          className={!bg ? styles.InputField : styles.InputFieldBlueBg}
          onChange={handleChange}
          value={email}
        />
        <img
          src={sendBtn}
          onClick={handleSubscribe}
          className={styles.Image}
          alt="Sub Button"
        />
      </div>
      {/* <CustomizedSnackbars
        open={open}
        severity={status}
        message={message}
        handleClose={handleClose}
      /> */}
    </div>
  );
}

export default FooterInputControlled;

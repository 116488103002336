import React from 'react'
import './ourvalue.css'
function OurValue() {
    return (
      <>
        <div className="choose-us-content p-10">
          {/* <div className="choose-us-content" style={{background: `radial-gradient(circle, rgba(60, 60, 60, 0.35) 0%, rgba(60, 60, 60, 0.35) 0%, rgba(60, 60, 60, 0.35) 0%), url('${chooseusbg}')center/cover no-repeat`}}> */}
          <div className="flex items-center justify-center">
            <div className="sm:w-[50%]" item xs={10} sm={12} md={4}>
              {/* <Fade left cascade> */}
                <h1
                  style={{
                    color: "black",
                    fontSize: "30px",
                    fontWeight: "bold",
                  }}
                >
                  What We Do
                </h1>
                <p style={{ color: "gray" }}>
                  Freduliphy EduMed Services is a specialized consultancy firm
                  based in Nigeria and India, dedicated to bridging the gap
                  between Nigerians and top-tier educational and medical
                  services in India
                </p>
              {/* </Fade> */}
              <br />
              <br />
            </div>
          </div>

          {/* <div style={{display:"auto", marginBottom:-5, overflow:"hidden"}}>
      <img style={{width:"100%", marginBottom:-2}} src={curveSvg} alt="Bottom Curve" />
  </div> */}
        </div>
      </>
    );
}

export default OurValue

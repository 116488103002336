import { NavLink, Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import logo2 from "../assets/logo2.png";
import arrowUp from "../assets/arrnew.png";
import FooterInputControlled from "./footerInput";

const Footer = ({ to }) => {
  return (
    <footer
      style={{
        background: "#20B993",
        marginTop: 15,
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
      className="px-10"
    >
      <div className="container  flex flex-wrap justify-between">
        <div className=" w-full ss:w-1/2 md:w-1/4 mb-4 ss:mb-0">
          <div
            // className=" bg-[#20B993]"
            style={{
              // padding: theme.spacing(1),
              backgroundColor: "#20B993",
              display: "flex",
              flexDirection: "column",
              // color: theme.palette.text.secondary,
            }}
            elevation={0}
          >
            <img
              src={logo2}
              alt="Freduliphy Edumed"
              style={{ width: "200px", marginLeft: "-29px" }}
            />
            <p variant="body1" className=" py-[20px] text-[white]">
              Subscribe with your email for updates on our services.
            </p>
            <FooterInputControlled bg={true} />
          </div>
        </div>
        <div className=" w-full ss:w-1/2 md:w-1/4 mb-4 ss:mb-0">
          <div
            elevation={0}
            style={{
              // padding: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#20B993",

              // color: theme.palette.text.secondary,
            }}
            className="md:pl-10"
          >
            <p variant="h5" className="pb-[10px] text-[white]">
              Who We Are
            </p>

            <NavLink
              className=" no-underline my-[10px] text-[white] "
              to="/about"
            >
              <p>About Us</p>
            </NavLink>
            <Link className=" no-underline my-[10px] text-[white] " to="/about">
              <ap>Meet Our Team</ap>
            </Link>
            <Link className=" no-underline my-[10px] text-[white] " to="/">
              <p>FAQ</p>
            </Link>
            <NavLink
              className=" no-underline my-[10px] text-[white] "
              to="/services"
            >
              <p>Our Services</p>
            </NavLink>
          </div>
        </div>
        <div
          className="w-full ss:w-1/2 md:w-1/4 mb-4 ss:mb-0"
          item
          xs={12}
          md={3}
          sm={6}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#20B993",
            }}
            elevation={0}
          >
            <p variant="h5" className="  text-white ">
              Our Services
            </p>
            <NavLink
              className="my-[10px]"
              style={{
                textDecoration: "none",

                color: "white",
              }}
              to="/services"
            >
              <p> Study Overseas</p>
            </NavLink>
            <NavLink
              className="my-[10px]"
              style={{
                textDecoration: "none",

                color: "white",
              }}
              to="/services"
            >
              <p>Medical Services Abroad</p>
            </NavLink>
            <NavLink
              className="my-[10px]"
              style={{
                textDecoration: "none",

                color: "white",
              }}
              to="/services"
            >
              <p>Edumed Consultation</p>
            </NavLink>
          </div>
        </div>
        <div className="w-full ss:w-1/2 md:w-1/4 mb-4 ss:mb-0">
          <div
            // className={classes.div}
            style={{
              // padding: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#20B993",
              // color: theme.palette.text.secondary,
            }}
            elevation={0}
          >
            <p variant="h5" className="mb-[10px] text-white ">
              Contact Us
            </p>

            <div mb={3} className="flex my-[10px] text-[white]">
              <p style={{ marginLeft: 10 }}>
                {" "}
                Plot 18, Street 9, Radio Estate, Ozuoba,
                <br /> Port Harcourt, Rivers State, Nigeria.
              </p>
            </div>
            <div mb={3} className="flex  my-[10px] text-[white]">
              <p style={{ marginLeft: 10 }}> fredulihy.edumeds23@gmail.com</p>
            </div>
            <div className="flex  my-[10px] text-[white]">
              <div style={{ marginLeft: 10 }}>
                <p>+234 913 035 0435 (Nigeria)</p>
                <p>+91 720 421 9214 (India)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="pt-2" style={{ display: "flex", alignItems: "center" }}>
          <p style={{ marginLeft: 2, color: "white" }}>
            @ Copyright {new Date().getFullYear()} Freduliphy Edumed Services
          </p>
        </div>
        <Scroll activeClass="active" to={to} spy={true} smooth={true}>
          <div
            style={{
              background: "white",
              height: 50,
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img style={{ height: 30 }} src={arrowUp} alt="Arrow up" />
          </div>
        </Scroll>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import servicesImage from "../../assets/servebg.png";

import Banner from "../../components/Banner/Banner";
import OurValue from "../../components/Ourvalue/OurValue";
import Footerblue from "../../components/Footer";
import rn4 from "../../assets/rn3.svg";
import rn3 from "../../assets/rn1.svg";
import rn1 from "../../assets/rn2.svg";
import goodPland from "../../assets/rn4.svg";
import HrLine from "../../components/HrLine";

function ServicePage() {
  // const classes = useStyles()
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("sm"));
  // const smallScreens = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="services" >
      <Helmet>
        <title>Services | Freduliphy</title>
        <meta
          name="description"
          content="Freduliphy EduMed Services is a specialized consultancy firm based in Nigeria, dedicated to bridging the gap between Nigerians and top-tier educational and medical services in India."
        />
      </Helmet>
      <Banner head="Services" bg={servicesImage} />

      <br />
      <br />
      <br />

      <OurValue />

      <div
        // className={matches ? "w-100%" : "w-100%"}
        className="ss:flex block justify-center mx-auto px-10"
      >
        <div
          className="sm:w-[25%] ss:w-[100%] ss:mr-4  ml-0"
          // className={
          //   matches
          //     ? "w-full md:w-auto md:ml-4 overflow-hidden"
          //     : "overflow-hidden"
          // }
        >
          {/* <Slide left> */}
            <div
              className="p-5 rounded"
              // elevation={0}
              style={{
                background: "#f26b48",
                height: "250px",
                // color: theme.palette.text.secondary,
              }}
            >
              <h1
                component="h1"
                variant="h5"
                style={{ color: "white", fontSize: "25px" }}
              >
                Study Overseas
              </h1>

              <p style={{ color: "white" }}>
                Freduliphy EduMed Services offers University Admissions
                Consultancy, Scholarship Assistance, Language and Cultural
                Orientation, and Accommodation and Travel Guidance.
              </p>
            </div>
          {/* </Slide> */}
        </div>

        <div className=" sm:w-[25%] ss:w-[100%] ss:ml-4  ml-0 ss:mt-0 mt-4 ">
          {/* <Slide right> */}
            <div
              className="p-5 rounded"
              elevation={0}
              style={{
                background: "#20B993",
                height: "250px",
                // color: theme.palette.text.secondary,
              }}
            >
              <h1
                color="white"
                component="h1"
                variant="h5"
                style={{ color: "white", fontSize: "25px" }}
              >
                Medical Services
              </h1>
              <p style={{ color: "white" }}>
                We offer Medical Treatment Consultancy, Medical Visa Assistance,
                Travel and Accommodation Services, and Post-treatment Care
              </p>
              {/* <Typography>We communicate effectively</Typography> */}
            </div>
          {/* </Slide> */}
        </div>
      </div>

      <div>
        <br />
        <br />

        <div>
          {/* <Fade top> */}
            <HrLine title=" Our Process" />
          {/* </Fade> */}
          {/* <Slide right> */}
            <h1 className="text-center mt-10 text-[30px] font-semibold">
              How We Work
            </h1>
            <div style={{ width: "100%" }}>
              <div
                // className={classes.underLine}
                style={{
                  display: "flex",
                  marginTop: 0,
                  justifyContent: "center",
                }}
              >
                <div
                  // className={classes.markYellow}
                  style={{
                    width: 70,
                    height: 5,
                    marginLeft: -90,
                    // background: theme.palette.yellow.main,
                    background: "#f26b48",
                  }}
                ></div>
                <div
                  // className={classes.markBlue}
                  style={{
                    width: 20,
                    height: 5,
                    marginLeft: 5,
                    // background: theme.palette.primary.main,
                    background: "#20B993",
                  }}
                ></div>
              </div>
            </div>
          {/* </Slide> */}
          <br />
          <br />

          {/* How we Work */}
          <div className="p-10  m-0  mx-auto  flex-1  flex ">
            <div className="grid w-[100%] xs:grid-cols-1 grid-cols-1 ss:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4  md:grid-cols-3 gap-4 justify-center">
              <div>
                {/* <Slide left> */}
                  <div
                    className="p-2"
                    style={{
                      border: "1px solid #C0C0C0",
                      boxSizing: "border-box",
                      borderRadius: "5px",
                      // padding: theme.spacing(2),
                      height: "320px",
                    }}
                    elevation={0}
                  >
                    <h1
                      // className={classes.processSteps}
                      style={{
                        textAlign: "end",
                        fontSize: "44px",
                        fontWeight: "500",
                        color: "#EDEBEB",
                      }}
                    >
                      01
                    </h1>
                    <img src={rn4} alt="Request Meeting" />
                    <h6
                      variant="h6"
                      // className={classes.processSubTextHead}
                      style={{ color: "#585858", fontStyle: "normal" }}
                    >
                      Initial Consultation
                    </h6>
                    <p
                      // className={classes.processSubText}
                      style={{ color: "#000000", fontStyle: "normal" }}
                    >
                      Clients are provided with a free initial consultation to
                      understand their specific needs and aspirations.
                    </p>
                  </div>
                {/* </Slide> */}
              </div>
              <div item xs={12} sm={6} md={3}>
                {/* <Slide top> */}
                  <div
                    className="p-2"
                    style={{
                      border: "1px solid #C0C0C0",
                      boxSizing: "border-box",
                      borderRadius: "5px",
                      // padding: theme.spacing(2),
                      height: "320px",
                    }}
                    elevation={0}
                  >
                    <h1
                      // className={classes.processSteps}
                      style={{
                        textAlign: "end",
                        fontSize: "44px",
                        fontWeight: "500",
                        color: "#EDEBEB",
                      }}
                    >
                      02
                    </h1>
                    <img src={rn1} alt="Request Meeting" />
                    <h6
                      variant="h6"
                      // className={classes.processSubTextHead}
                      style={{ color: "#000000", fontStyle: "normal" }}
                    >
                      Tailored Plan Creation
                    </h6>
                    <p
                      // className={classes.processSubText}
                      style={{ color: "#585858", fontStyle: "normal" }}
                    >
                      Based on the consultation, a bespoke plan is crafted,
                      detailing potential institutions, procedures, costs, and
                      timelines.
                    </p>
                  </div>
                {/* </Slide> */}
              </div>
              <div item xs={12} sm={6} md={3}>
                {/* <Slide bottom> */}
                  <div
                    className="p-2"
                    style={{
                      border: "1px solid #C0C0C0",
                      boxSizing: "border-box",
                      borderRadius: "5px",
                      // padding: theme.spacing(2),
                      height: "320px",
                    }}
                    elevation={0}
                  >
                    <h1
                      // className={classes.processSteps}
                      style={{
                        textAlign: "end",
                        fontSize: "44px",
                        fontWeight: "500",
                        color: "#EDEBEB",
                      }}
                    >
                      03
                    </h1>
                    <img src={rn3} alt="Add" />
                    <h6
                      variant="h6"
                      // className={classes.processSubTextHead}
                      style={{ color: "#000000", fontStyle: "normal" }}
                    >
                      Application & paperwork Assistance
                    </h6>
                    <p
                      // className={classes.processSubText}
                      style={{ color: "#585858", fontStyle: "normal" }}
                    >
                      A dedicated case manager assists clients in application
                      processes, paperwork, and legalities.
                    </p>
                  </div>
                {/* </Slide> */}
              </div>
              <div item xs={12} sm={6} md={3}>
                {/* <Slide right> */}
                  <div
                    className="p-2"
                    style={{
                      border: "1px solid #C0C0C0",
                      boxSizing: "border-box",
                      borderRadius: "5px",
                      // padding: theme.spacing(2),
                      height: "320px",
                    }}
                    elevation={0}
                  >
                    <h1
                      // className={classes.processSteps}
                      style={{
                        textAlign: "end",
                        fontSize: "44px",
                        fontWeight: "500",
                        color: "#EDEBEB",
                      }}
                    >
                      04
                    </h1>
                    <img src={goodPland} alt="Plan" />
                    <h6
                      variant="h6"
                      // className={classes.processSubTextHead}
                      style={{ color: "#000000", fontStyle: "normal" }}
                    >
                      Continuous Support
                    </h6>
                    <p
                      // className={classes.processSubText}
                      style={{ color: "#585858", fontStyle: "normal" }}
                    >
                      Freduliphy EduMed provides continuous 24/7 helpline and
                      on-ground support for individuals traveling from Nigeria
                      to India and during their entire stay.
                    </p>
                  </div>
                {/* </Slide> */}
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
      <br />
      <br />
      <Footerblue to={"services"} />
    </div>
  );
}

export default ServicePage;

import { navLinks } from "../constant";
import { close, menu, logo1, logo2 } from "../assets/imgFile";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [toggle, setToggle] = useState(false);
  const [navBarColour, setnavBarColour] = useState(false);

  const changeBackgroung = () => {
    if (window.scrollY >= 20) {
      setnavBarColour(true);
    } else {
      setnavBarColour(false);
    }
  };

  window.addEventListener("scroll", changeBackgroung);

  //////////////////////////////////////////
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [logo, setLogo] = useState(isAuthenticated ? logo1 : logo2);

  ////////////////////////////////////
   const [activeLink, setActiveLink] = useState("");
  const handleLinkClick = (linkId) => {
    setActiveLink(linkId);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position
      const scrollPosition = window.scrollY;

      // Set the logo based on the scroll position
      if (scrollPosition > 20) {
        setLogo(isAuthenticated ? logo2 : logo1);
      } else {
        setLogo(isAuthenticated ? logo1 : logo2);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAuthenticated]); // Re-run the effect when the isAuthenticated state changes

  const toggleAuthentication = () => {
    setIsAuthenticated((prevIsAuthenticated) => !prevIsAuthenticated);
  };

   const closeNav = () => {
     setToggle(false);
   };

  return (
    <>
      {/* className="justify-between w-full flex h-[100px] item-center navbar py-9 bg-[#242525] text-white  container mx-auto" */}
      <nav
        className={
          navBarColour
            ? "justify-between w-full  flex h-[100px] item-center navbar py-6 active  text-black  container mx-auto fixed top-0"
            : "justify-between  w-full  flex h-[100px] item-center navbar py-6 unactive text-white  container mx-auto"
        }
      >
        <Link to="/">
          <img
            src={logo}
            alt=""
            className="  w-[250px] sm:h-[70px]  h-[70px] sm:ml-[-10px] ml-[-10px]  sm:mt-[-10px] mt-[-10px]"
          />
        </Link>
        <ul className=" mr-[100px] hidden md:flex md:h-full md:justify-around md:items-center">
          {navLinks.map((link, i) => {
            return (
              <li
                active={activeLink === link.id}
                onClick={() => handleLinkClick(link.id)}
                key={link.id}
                className={` hidden sm:inline-block  no-underline transition-colors duration-300 hover:text-[#20B993] hover:font-bold text-lg ml-5    ${
                  i === navLinks.length - 1 ? "mr-0" : "mr-8"
                } ${link.id === activeLink ? "text-[#20B993]" : " "} `}
              >
                {/* {link.title} */}
                <Link to={link.path}> {link.title} </Link>
              </li>
            );
          })}
        </ul>
        {/* SMALL SCREEN */}

        <div className=" sm:hidden flex flex-1 justify-end item-center   ">
          <div className=" h-[100px] mt-[-30px] w-[100px] p-5 rounded pt-[30px] z-10">
            <img
              src={`${toggle ? close : menu}`}
              alt="hambuger"
              className="w-[40px] h-[40px] object-contian mr-5  "
              onClick={() => setToggle((prev) => !prev)}
            />
          </div>

          <div
            className={`${
              toggle ? "flex" : "hidden"
            } p-4 bg-[#20B993]  absolute top-[0px] right-[0px] mx-0 my-0 min-w-[100%] pt-[30px] rounded-[5px] sidebar`}
          >
            <ul className="list-none flex flex-col justify-end item-center flex-1  ">
              {navLinks.map((link, i) => {
                return (
                  <li
                    key={link.id}
                    className={`pl-[4%] border-b text-gray-800 border-white font-poppins font-normal text-left cursor-pointer text-[20px] ${
                      i === navLinks.length - 1 ? "mb-20" : "mb-0"
                    } mt-[55px] text-black hover:text-[white] `}
                  >
                    <Link to={link.path} onClick={closeNav}>
                      {" "}
                      {link.title}{" "}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import contactus from "../../assets/contactus1.png";
import Banner from "../../components/Banner/Banner";
import Iframe from "react-iframe";
import Footerblue from "../../components/Footer";
import Analytics from "../../Analytics";
// EMAIL
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../../css/SendEmail.css";

function ContactUs() {

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [message, setMessage] = React.useState(false);


  const initialValues = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };
  const handleTrackContactButton = () => {
    Analytics(
      "User in contact page",
      "Used the contact us form",
      "contact us form submit"
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleSubmitContactForm = (values, helpers) => {
    console.log("Helpers part", values);
    if (status === "success") {
      helpers.resetForm(initialValues);
      // setStatus("success")
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // EMAIL
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_b5lxs68",
        "template_czgwevl",
        form.current,
        "aCxThW3fwY31g7de2"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div id="contact">
      <Helmet>
        <title>Contact Us | SmartIV Hauz</title>
        <meta
          name="description"
          content="At SmartIV Hauz, we offer solutions that will help to boost the quality of your product or service delivery in order to create value and maximize profit."
        />
      </Helmet>
      <Banner head="Contact Us" bg={contactus} />

      <div>
        <h1
          className="pl-4"
          style={{
            fontSize: "24px",
            // color: theme.palette.primary.main,
            marginTop: 35,
          }}
        >
          Google Map
        </h1>

        <Iframe
          className="p-2 rounded-sm"
          styles={{ border: "none", borderRadius: 8 }}
          src="https://maps.google.com/maps?width=524&amp;height=392&amp;hl=en&amp;q=Plot%2018,%20Street%209,%20Radio%20Estate,%20Ozuoba,%20Port%20Harcourt,%20Rivers%20State,%20Nigeria.%20Port%20Harcourt+(Freduliphy%20EduMed%20Services)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          width="100%"
          height="400px"
        />
      </div>
      {/* EMAIL */}
      <div className="mx-auto">
        <div
          className="contactUs rounded-sm text-center sm:text-center mx-auto    
        my-14 w-full"
        >
          <div className="content">
            <div className="left-side">
              <div className="address details">
                <i className="fas fa-map-marker-alt"></i>
                <div className="topic">Address</div>
                <div className="text-one">
                  Plot 18, Street 9, Radio Estate, Ozuoba, Port Harcourt,
                </div>
                <div className="text-two"> Rivers State, Nigeria.</div>
              </div>
              <div className="phone details">
                <i className="fas fa-phone-alt"></i>
                <div className="topic">Phone</div>
                <div className="text-one">+234 913 035 0435 (Nigeria)</div>
                <div className="text-two">+91 720 421 9214 (India)</div>
              </div>
              <div className="email details">
                <i className="fas fa-envelope"></i>
                <div className="topic">Email</div>
                <div className="text-one">fredulihy.edumeds23@gmail.com</div>
              </div>
            </div>
            <div className="right-side">
              <div className="topic-text text-[#20B993] ">
                Send us a message
              </div>
              <p>
                Our team of experts is committed to providing you with the best
                possible response. Please don't hesitate to contact us through
                any of our communication channels
              </p>
              <form ref={form} onSubmit={sendEmail}>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Enter your name"
                    name="user_name"
                    required
                  />
                </div>
                <div className="input-box">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    name="user_email"
                    required
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Enter Subject"
                    name="subject"
                    required
                  />
                </div>
                <div className="input-box message-box">
                  <textarea name="message" placeholder="Message" required />
                </div>
                <div className="button">
                  <input type="submit" value="Send Now" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footerblue to={"contact"} />
    </div>
  );
}

export default ContactUs;

import Fred from "../../assets/Fred.jpg";
import Peace from "../../assets/Peace.jpg";
import Ayodele from "../../assets/Ayodele.jpg";
import Preye from "../../assets/Preye.jpg";

// Peace Monday (Secretary)

// Ayodele Idowu (Marketing Manager)

// Preye Jonah (Strategic Manager)


const Team = [
  {
    name: "Fred Dumo",
    Portfolio: "Founder/CEO",
    img: Fred,
  },
  {
    name: "Peace Monday ",
    Portfolio: "Secretary",
    img: Peace,
  },
  {
    name: "Ayodele Idowu",
    Portfolio: "Marketing Manager",
    img: Ayodele,
  },
  {
    name: "Preye Jonah",
    Portfolio: "Strategic Manager",
    img: Preye,
  },
  // {
  //   name: "Alexander Ogoh",
  //   Portfolio: "Operations Manager",
  //   img: Ogoh,
  // },
  // {
  //   name: "Afolabi Jamiu",
  //   Portfolio: "Chief Marketing Officer",
  //   img: afolabi,
  // },
  // {
  //   name: "Bio-Ibogomo, Numomoipre",
  //   Portfolio: "Marketing Manager",
  //   img: Numomoipre,
  // },
  //   {
  //    name:"Adegeye Emmanuel",
  //    Portfolio:"Tech Manager",
  //    img:Emmanuel,

  //   },
  //   {
  //    name:"Essien Saviour",
  //    Portfolio:"CTO",
  //    img:Essien,

  //   },
  //   {
  //    name:"Pst. Mrs Ebinabo Lorhi",
  //    Portfolio:"Pastor FCC",
  //    img:ebinabo_lorhi,

  //   },
  //   {
  //    name:"Pst. Anderson Zikkenah",
  //    Portfolio:"Pastor FCC",
  //    img:anderson_zikenah,

  //   },
  //   {
  //    name:"Pst. Mrs Dinah Ekpete",
  //    Portfolio:"Pastor FCC",
  //    img:dinah_ekpete,

  //   },
  //   {
  //    name:"Pst. Presidor Richard",
  //    Portfolio:"Pastor FCC",
  //    img:presidor_richard,

  //   },
  //   {
  //    name:"Pst. Deo Ebitare",
  //    Portfolio:"Pastor FCC",
  //    img:deo_ebitare,

  //   },
  //   {
  //    name:"Pst. Gabriel CID",
  //    Portfolio:"Pastor FCC",
  //    img:gabriel_cid,

  //   },
  //   {
  //    name:"Pst. Shadrack Benson",
  //    Portfolio:"Pastor FCC",
  //    img:benson_shadrack,

  //   },
];

export default Team;

import React, { useEffect } from "react";

import { Helmet } from "react-helmet";

import aboutafterbanner from "../assets/about_afterbanner.png";
import aboutImage from "../assets/doctors.jpg";

import HrLine from "../components/HrLine";

import Banner from "../components/Banner/Banner";

import OurTeam from "../components/OurTeam/OurTeam";
import Footer from "../components/Footer";

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="about">
      <Helmet>
        <title>About | Freduliphy Edumed</title>
        <meta
          name="description"
          content="Freduliphy EduMed Services is a specialized consultancy firm based in Nigeria and India, dedicated to bridging the gap between Nigerians and top-tier educational and medical services in India. "
        />
      </Helmet>
      <Banner head="About Us" bg={aboutImage} />
      <br />
      <br />
      <br />
      <br />

      <div className="choose-us-content">
        <div
          container
          justify="center"
          alignItems="center"
          spacing={1}
          style={{
            margin: 0,
            marginTop: 0,
            width: "100%",
            alignItems: "center",
          }}
          className="flex  "
        >
          {/* 2 YEARS EXPERNCES  */}
          <div className=" md:block hidden w-[50%]">
            <div className="bg-transparent">
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={aboutafterbanner}
                  alt="about-satisfaction"
                  style={{ width: "70%", height: "50%" }}
                />
                <div>
                  <div
                    style={{
                      background: "#FFFFFF",
                      boxShadow: "0px 4px 10px 3px rgba(32, 185, 147, 0.05)",
                      height: "110px",
                      width: "190px",
                      display: "flex",
                      marginTop: -60,
                      marginLeft: "4%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      position: "absolute",
                      // backgroundColor: " #f26b48",
                    }}
                    // className=" border-green-500 rounded-lg shadow-lg bg-green-200"
                  >
                    <h5
                      className="text-[#20B993] text-[24px]"
                      component="h1"
                      variant="h5"
                    >
                      2 Years <span className=" text-gray-400">of</span>
                    </h5>
                    <h5
                      component="h1"
                      variant="h5"
                      className=" text-gray-400 text-[24px]"
                    >
                      Experience
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* TEXT AERA */}

          <div className="sm:w-[50%] w-[100%] sm:text-left text-center sm:px-2 px-4">
            {/* <Fade bottom> */}
              <h1
                className="pb-2 px-2 text-[20px] font-semibold"
                component="h1"
                variant="h6"
              >
                About Freduliphy EduMed Services
              </h1>
              {/* <Typography component="h1" variant="h6"></Typography> */}
            {/* </Fade> */}
            {/* <Fade right> */}
              <p className="px-2">
                Freduliphy EduMed Services is a specialized consultancy firm
                based in Nigeria, dedicated to bridging the gap between
                Nigerians and top-tier educational and medical services in
                India. Recognizing the increasing demand for quality education
                and medical services abroad, Freduliphy EduMed Services acts as
                a conduit, simplifying the process of seeking, applying, and
                availing world-class services in India.
              </p>
            {/* </Fade> */}
          </div>

          {/* <div className=" hidden" item xs={12} sm={12} md={6}>
            <div elevation={0} className="bg-transparent">
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  flexWrap: "wrap",
                  marginTop: 14,
                  flexDirection: "column",
                }}
              >
                <Fade left>
                  <img
                    src={aboutafterbanner}
                    alt="about-satisfaction"
                    style={{ width: "90%", height: "50%" }}
                  />
                </Fade>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{
                      background: "#FFFFFF",
                      boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.05)",
                      height: "110px",
                      width: "190px",
                      display: "flex",
                      marginTop: -60,
                      marginLeft: -50,
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      position: "absolute",
                    }}
                  >
                    <Slide left>
                      <h5 color="primary" component="h1" variant="h5">
                        2 Years <span style={{ color: "#585858" }}>of</span>
                      </h5>
                      <h5
                        component="h1"
                        variant="h5"
                        style={{ color: "#585858" }}
                      >
                        Experience
                      </h5>
                    </Slide>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <br />
      <br />

      <div className="contianer">
        <br />
        <br />
        <br />
        {/* <Fade top> */}
          <HrLine title="TEAM" />
        {/* </Fade>
        <Fade left> */}
          <h1
            className="font-semibold mt-10"
            style={{ textAlign: "center", color: "#585858", fontSize: "30px" }}
          >
            Meet Our Leadership
          </h1>
        {/* </Fade> */}
        {/* <Slide right> */}
          <div style={{ width: "100%" }}>
            <div
              // className={classes.underLine}
              style={{
                display: "flex",
                marginTop: 0,
                justifyContent: "center",
              }}
            >
              <div
                //   className={classes.markYellow}
                style={{
                  width: 70,
                  height: 5,
                  marginLeft: -90,
                  background: "#f26b48",
                }}
              ></div>
              <div
                //   className={classes.markBlue}
                style={{
                  width: 20,
                  height: 5,
                  marginLeft: 5,
                  background: "#20B993",
                }}
              ></div>
            </div>
          </div>
        {/* </Slide> */}
        <br />
        <br />
        <br />

        <OurTeam />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footer to={"about"} />
    </div>
  );
}

export default AboutPage;

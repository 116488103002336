// NavBar///////////
export const navLinks = [
  {
    id: "about",
    title: "Who We Are",
    path: "/about",
  },
  {
    id: "ourServices",
    title: "Our Services",
    path: "/services",
  },

  {
    id: "contact",
    title: "Contact",
    path: "/contact",
  },
];

import React, { useEffect } from "react";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import AboutPage from "./pages/OurServices";
import ServicePage from "./pages/ServicesPage/index";
import ContactUs from "./pages/ContactUs/index";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  return (
    <>
      <div className="w-full bg-[#f9f8f8]  overflow-hidden">
        <Router>
          <div className="w-[100vw] max-w[100vw]">
            <NavBar />
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/services" element={<ServicePage />} />
              <Route path="/contact" element={<ContactUs />} />
            </Routes>
          </div>
        </Router>
      </div>
    </>
  );
}

export default App;

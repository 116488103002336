import React, { useEffect, useState } from "react";

import customSoftbg from "../assets/consultation.png";
import logoDesign from "../assets/hospital.png";
import outsourcing from "../assets/education2.png";
import { Link } from "react-router-dom";
import vission from "../assets/eye.svg";
import target from "../assets/target.svg";
import belowProductsImage from "../assets/image2.jpg";
import teamImage from "../assets/team.jpg";
import good from "../assets/check.png";
import Footer from "../components/Footer";
import backImage from "../assets/herobg1.png";
import curveSvg from "../assets/self2.svg";
import leftArrow from "../assets/left-arrow.png";
import rightArrow from "../assets/right-arrow.png";

const slidesData = [
  {
    name: "GO FROM DREAMS TO DEGREE",
    description:
      "Embark on your journey to study at renowned universities worldwide without breaking the bank.",
    color: "#64ACC8",
  },
  {
    name: "EXCEPTIONAL HEALTHCARE SOLUTIONS?",
    description: "Discover world-class medical services in India and beyond.",
    color: "#7D85B1",
  },
];

const items = [
  {
    id: 1,
    title: "How do I start?",
    content:
      "Our services begins with a FREE consultation. Our team ofexperts are readily available to understand what your needsare to enable us with tailored offerings to exceed your expectations",
  },
  {
    id: 1,
    title: "How long does it take to start and conclude the process?",
    content:
      " Depending on the service and the availability of documents,the average time for completing the process for any of our services is 1 - 3 months.",
  },
  {
    id: 1,
    title: "How can you trust us?",
    content: "We have a track of success. We have a community of over 50 individuals who have benefitted from our services. We are duly registered with our team based in Nigeria and India.",
  },
];

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slidesData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slidesData.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slidesData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);


// Accordion
 
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div>
      {/* background: `  url('${backImage}') }} */}
      {/* LANDING PAGE */}
      <div className="h-[100vh] ">
        <div
          style={{
            background: ` radial-gradient(circle, rgba(60, 60, 60, 0.6) 0%, rgba(60, 60, 60, 0.6) 35%, rgba(60, 60, 60, 0.6) 100%), url('${backImage}')center/cover no-repeat`,
            // marginBottom: -5,
          }}
          className=" w-full h-[90vh] flex flex-col items-center justify-center"
        >
          <div>
            {slidesData.map((slide, index) => (
              <div
                key={slide.id}
                className={`  text-center ${
                  index !== currentSlide ? "hidden" : ""
                }`}
                style={{
                  // display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                  }}
                >
                  <div
                    style={{
                      // textAlign:"center",

                      color: "#FFFFFF",
                      // backgroundColor:'blue',
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className={`text-${slide.color} `}
                      style={{ fontSize: "16px", width: "60%" }}
                    >
                      {slide.name}
                    </p>
                    <h3
                      className={`text-${slide.color} py-4`}
                      style={{
                        fontSize: "26px",
                        width: "60%",
                        textAlign: "center",
                      }}
                    >
                      {slide.description}
                    </h3>
                  </div>
                </div>

                <Link style={{ textDecoration: "none" }} to="/services">
                  <button
                    style={{
                      borderRadius: "20px",
                      color: "#FFFFFF",
                      border: "1px solid white",
                      textTransform: "capitalize",
                      padding: '10px',

                      // zIndex: 100,
                    }}
                    // className="hover:border-[#20B993] hover:border-2 bg-[red]"
                    // variant="outlined"
                    // onClick={handleTrackStartNowButton}
                  >
                    Start Now
                  </button>
                </Link>
              </div>
            ))}
          </div>
          <div className="mt-4 mx-2 flex space-x-4 absolute left-0 right-0 justify-between">
            <button
              className="  bg-gray-500 hover:bg-gray-500 text-white font-bold py-2 px-2 rounded-[50%]"
              onClick={prevSlide}
            >
              <img src={leftArrow} alt="" className="h-4 w-4" />
            </button>
            <button
              className="  bg-gray-500 hover:bg-gray-500 text-white font-bold py-2 px-2 rounded-[50%]"
              onClick={nextSlide}
            >
              <img src={rightArrow} alt="" className="h-4 w-4" />
            </button>
          </div>
        </div>

        <div className="mt-[-18vh]">
          <img
            style={{ width: "100%" }}
            src={curveSvg}
            alt="Bottom Curve"
            className=" h-[30vh]"
          />
        </div>
      </div>
      {/* <HeroSectionContainer /> */}
      {/* WHAT WE DO */}
      <div className="choose-us-content mt-10">
        <h1 className="mx-auto">
          <span className="text-2xl font-semibold text-[#20B993] mx-auto">
            What We Do
          </span>
        </h1>

        <div style={{ width: "100%", marginLeft: "25px" }}>
          <div className="flex mt-2 justify-center">
            <div className="ml-[-90px] w-[70px] h-[5px] bg-red-400 "></div>
            <div className="ml-[5px] h-[5px] w-[20px] bg-[#20B993]"></div>
          </div>
        </div>
        <br />
        <div className="container row justify-center choose-us-content ">
          <div className="mx-auto sm:w-[50%]  " style={{ textAlign: "center" }}>
            <div className="mx-auto px-6  text-lg ">
              Recognizing the increasing demand for quality education and
              medical services abroad, Freduliphy EduMed Services acts as a
              conduit, simplifying the process of seeking, applying, and
              availing world-class services in India.
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
      {/* EDUCATIONAL SERVICES */}
      <div className="px-1 sm:px-1 md:px-1 lg:px-1 pt-8   container mx-auto">
        <div className=" container  mx-auto">
          <div
            className="grid  ss:grid-cols-3 gap-4 justify-center"
            // display="flex"
            // justifyContent="center"
            // spacing={2}
          >
            <div>
              {/* {classes.paperImage} */}
              <div
                className="h-[296px] w-[100%] "
                // elevation={0}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    background: `linear-gradient(90deg, rgba(17,15,15,.29) 0%, rgba(32,37,34,.29) 100%), url('${outsourcing}')center/cover no-repeat`,
                  }}
                >
                  <div style={{ flex: 1 }}></div>
                  <h3
                    className=" font-semibold mb-10 px-2 "
                    style={{
                      color: "#FFFFFF",
                      justifySelf: "center",
                      fontSize: "36px",
                      marginLeft: "10px",
                    }}
                  >
                    Educational Services
                  </h3>
                </div>
              </div>
            </div>
            <div>
              {/* {classes.paperImage} */}
              <div
                className="h-[296px] w-[100%]"
                // elevation={0}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    background: `linear-gradient(90deg, rgba(17,15,15,.29) 0%, rgba(32,37,34,.29) 100%), url('${logoDesign}')center/cover no-repeat`,
                  }}
                >
                  <div style={{ flex: 1 }}></div>
                  <h3
                    className=" font-semibold mb-10 px-2 "
                    style={{
                      color: "#FFFFFF",
                      justifySelf: "center",
                      fontSize: "36px",
                      marginLeft: "10px",
                    }}
                  >
                    Medical Services
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <div className="h-[296px] w-[100%]" elevation={0}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    background: `linear-gradient(90deg, rgba(17,15,15,.29) 0%, rgba(32,37,34,.29) 100%), url('${customSoftbg}')center/cover no-repeat`,
                  }}
                >
                  <div style={{ flex: 1 }}></div>
                  <h3
                    className=" font-semibold mb-10 px-2 "
                    style={{
                      color: "#FFFFFF",
                      justifySelf: "center",
                      fontSize: "36px",
                      marginLeft: "10px",
                    }}
                  >
                    Consultation
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* WHY WE EXIST */}
      <section>
        <div className="choose-us-content mt-14">
          {/* clsx(classes.textBelowHero, classes.colorHeader) */}
          {/* {classes.smartVfred} */}
          {/* {classes.underLine} */}
          <h1 className="mx-auto">
            <span className="text-[24px] font-bold text-[#20B993] mx-auto">
              Why We Exist{" "}
            </span>
          </h1>

          <div style={{ width: "100%", marginLeft: "25px" }}>
            <div className="flex mt-[10px] justify-center">
              {/* {classes.markYellow} */}
              <div className="ml-[-90px] w-[70px] h-[5px] bg-red-400 "></div>
              {/* {classes.markBlue} */}
              <div className="ml-[5px] h-[5px] w-[20px] bg-[#20B993]"></div>
            </div>
          </div>
          <br />
        </div>
        <div>
          <div className=" p-0 border-2 sm:flex mx-auto">
            <div className="xs:w-[100%] sm:w-[100%] lg:w-[40%] md:w-[40%]  ">
              {/* {classes.imageSection} */}
              <div className="">
                {/* {classes.imgMission} */}
                {/* {belowProductsImage} */}
                <img
                  src={belowProductsImage}
                  className="w-[100%] lg:h-[350px] md:h-[350px] "
                  alt="Mission"
                />
              </div>
            </div>
            <div className="ss:flex block lg:w-[60%] md:w-[60%]">
              <div className="xs:w-[100%] sm:w-[50%] lg:w-[50%]">
                {/* {clsx(classes.paper, classes.backCover)} */}
                <div
                  className="h-[350px]"
                  elevation={0}
                  style={{
                    backgroundColor: "#20B993",
                    // height: "350px",
                    borderRadius: "0px",
                    padding: "10px",
                  }}
                  // className="rounded-0 h-[330px] bg-[#20B993]"
                >
                  {/* {classes.bgContentVisionMission} */}
                  <div className="flex p-[2px] flex-col">
                    {/* {vission} */}
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={vission}
                      alt="Vision"
                    />
                    {/* {classes.visionMisionText} */}
                    <h4
                      style={{ fontSize: "24px", marginTop: "-23px" }}
                      className=" text-gray-200 font-semibold  "
                    >
                      Vision
                    </h4>
                    {/* {classes.statementText} */}
                    <p
                      variant="body5"
                      style={{ marginTop: "15px" }}
                      className=" text-gray-200  "
                    >
                      To emerge as the foremost consulting partner, linking
                      Africans to dependable and cost-effective educational and
                      medical services, shaping a brighter future by 2034.
                    </p>

                    {/* {classes.statementLinks} */}
                    {/* <Link
                      className="no-underline hover:no-underline"
                      to="/about"
                    >
                     
                      <button
                        variant="contained"
                        color="#20B993"
                        style={{
                          borderRadius: "25px",
                          width: "140px",
                          height: "38px",
                          textTransform: "capitalize",
                          color: "#20B993",
                          marginTop: "15px",
                        }}
                        
                      >
                        Learn More
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="xs:w-[100%] sm:w-[50%] lg:w-[50%]">
                {/* {clsx(classes.paper, classes.backCover)} */}
                <div
                  className="lg:h-[350px]"
                  elevation={0}
                  style={{
                    backgroundColor: "#20B993",
                    height: "350px",
                    borderRadius: "0px",
                  }}
                  // className="rounded-0 h-[330px] bg-[#20B993]"
                >
                  {/* {classes.bgContentVisionMission} */}
                  <div className="flex  flex-col p-[10px]">
                    {/* {target} */}
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={target}
                      alt="Freduliphy Mission Statement"
                    />
                    {/* {classes.visionMisionText} */}
                    <h4
                      style={{ fontSize: "24px", marginTop: "-23px" }}
                      className=" text-gray-200 font-semibold "
                      // className="text-[24px] mt-[-23px]"
                    >
                      Mission
                    </h4>
                    {/* {classes.statementText} */}
                    <p
                      // variant="body5"
                      style={{ marginTop: "15px", display: "block" }}
                      className=" text-gray-200  "
                      // className="mt-[-15px]"
                    >
                      Our mission is to empower Nigerians with seamless access
                      to world-class education and medical services in India and
                      beyond, fostering individual growth, well-being and
                      strengthening bilateral ties between nations.
                    </p>
                    {/* {classes.statementLinks} */}
                    {/* <Link
                      className="no-underline hover:no-underline"
                      to="/about"
                    >
                      
                      <button
                        variant="contained"
                        className=""
                        style={{
                          borderRadius: "25px",
                          width: "140px",
                          height: "38px",
                          textTransform: "capitalize",
                          color: "#20B993",
                          marginTop: "15px",
                          // marginBottom: '-50px'
                        }}
                        // onClick={handleTrackKnowMoreButton}
                      >
                        Learn More
                      </button>
                    </Link> */}
                  </div>
                  {/* {classes.divider} */}
                  {/* <Divider
                  orientation="vertical"
                  lightgrey
                  className="bg-gray-200"
                  style={{
                    marginTop: -282,
                    height: 290,
                    backgroundColor: "rgb(229 231 235 / var(--tw-bg-opacity))",
                  }}
                /> */}
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </section>
      {/* CORE VALUES */}
      <div>
        <div className="choose-us-content mt-14">
          {/* clsx(classes.textBelowHero, classes.colorHeader) */}
          {/* {classes.smartVfred} */}
          {/* {classes.underLine} */}
          <h1 className="mx-auto">
            <span className="text-[24px] font-bold text-[#20B993] mx-auto">
              Core Values{" "}
            </span>
          </h1>

          <div style={{ width: "100%", marginLeft: "25px" }}>
            <div className="flex mt-[10px] justify-center">
              {/* {classes.markYellow} */}
              <div className="ml-[-90px] w-[70px] h-[5px] bg-red-400 "></div>
              {/* {classes.markBlue} */}
              <div className="ml-[5px] h-[5px] w-[20px] bg-[#20B993]"></div>
            </div>
          </div>
          <br />
          <br />
          {/* {classes.height} */}
          <div
            // spacing={0}
            style={{
              // height: "500px",
              // display: "flex",
              alignItems: "center",
              background: "white",
            }}
            className="mx-auto w-full ss:flex  block"
          >
            {" "}
            <div
              className=" ss:w-[50%]  md:w-[50%] sm:w-[50%] lg:w-[50%]"
              item
              xs={12}
              sm={12}
              md={5}
            >
              <div elevation={0} style={{ marginLeft: 0, background: "white" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    height: "400px",
                    background: `  url('${teamImage}') center/cover no-repeat`,
                  }}
                >
                  {/* <img
                    src={teamImage}
                    alt="about-satisfaction"
                    style={{ width: "100%", height: "400px" }}
                  /> */}
                </div>
              </div>
            </div>
            <div
              item
              xs={12}
              sm={12}
              md={7}
              className=" ss:w-[50%]  md:w-[50%] sm:w-[50%] lg:w-[50%] "
            >
              {/* {clsx(classes.paper, classes.faq)} */}
              <div
                className=""
                style={{
                  borderRadius: 0,
                  background: "#EDF4FA",
                  height: "400px",
                  // color: theme.palette.text.secondary,
                }}
                elevation={0}
              >
                <div className="text-left">
                  <div className="px-10   w-[100%] ">
                    <p
                      className=" text-left text-gray-500 pt-10  "
                      style={{ fontSize: "20px" }}
                    >
                      We are guided by the following values
                    </p>
                    <br />
                    {/* {classes.values} */}
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "15px",
                      }}
                    >
                      <img
                        src={good}
                        alt="Reliability"
                        width="25px"
                        height="25px"
                      />{" "}
                      <p style={{ marginLeft: "6px" }}>Reliability</p>
                    </div>
                    <br />
                    {/* {classes.values} */}
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "15px",
                      }}
                    >
                      <img
                        src={good}
                        alt="Affordability"
                        width="25px"
                        height="25px"
                      />{" "}
                      <p style={{ marginLeft: "6px" }}>Affordability</p>
                    </div>
                    <br />
                    {/* {classes.values} */}
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "15px",
                      }}
                    >
                      <img
                        src={good}
                        alt="Integrity"
                        width="25px"
                        height="25px"
                      />{" "}
                      <p style={{ marginLeft: "6px" }}>Integrity</p>
                    </div>
                    <br />
                    <br />
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://www.instagram.com/freduliphy_edumed/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {/* {classes.btnCompleted} */}
                      <button
                        className=""
                        style={{
                          background: "#20B993",
                          borderRadius: 20,
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          padding: "10px",
                          marginBottom: "20px",
                          // padding: theme.spacing(1),
                          // hover background: theme.palette.yellow.main,
                        }}
                        color="primary"
                      >
                        Explore Our Culture
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* SMALL SCREEN */}
            {/* {!matches && (
              <Grid item xs={12} sm={12} md={4}>
                <Paper
                  elevation={0}
                  // style={{ background: "transparent" }}
                  style={{ marginLeft: -150, background: "transparent" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={teamImage}
                      alt="about-satisfaction"
                      style={{ width: "70%", height: "240px" }}
                    />
                  </div>
                </Paper>
              </Grid>
            )} */}
          </div>
        </div>
      </div>
      {/* FAQ */}
      {/* {clsx(classes.faq, classes.faqContent)} */}
      <div
        className="px-10 justify-between sm:flex block "
        style={{
          // display: "flex",
          flexWrap: "wrap",
          // marginBottom: theme.spacing(0),
        }}
      >
        {/* {classes.faq} */}

        <div className="sm:w-[50%] w-[100%]">
          {/* {classes.faq} */}
          <div>
            <br />
            <br />
            <h4 className="text-[23px] font-semibold">FAQ</h4>
            <h4 className="text-[22px] "> Most common answers and questions</h4>
          </div>
        </div>
        <div className="sm:w-[50%] w-[100%]">
          <br />
          <br />
          <div className="max-w-md mx-auto">
            {items.map((item, index) => (
              <div key={index} className="mb-4 border rounded overflow-hidden">
                <div
                  className={`flex items-center justify-between p-4 cursor-pointer ${
                    openIndex === index
                      ? " text-black bg-gray-200"
                      : " bg-gray-200"
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="text-lg ">{item.title}</span>
                  <svg
                    className={`w-6 h-6 transition-transform transform ${
                      openIndex === index ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
                {openIndex === index && (
                  <div className="p-4 bg-gray-200">
                    <p className="">{item.content}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <br />
        <br />

        <br />
      </div>
      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Home;

import React from "react";
import { Link } from "react-router-dom";
import Team from "./ourteamcontent";

export default function OurTeam() {
  return (
    <div className="bg-[white] px-2 sm:px-2 md:px-2 lg:px-2 pt-8   container mx-auto">
      <div className="container mx-auto">
        <div className=" grid ss:grid-cols-4 gap-4 justify-center">
          {Team.map((img, i) => {
            return (
              // <Link to="/Mrakwa">
              <div
                className="shadow-lg max-w-sm p-2 bg-gray-100 rounded-sm h-[400px] "
                key={img.id}
              >
                <img
                  src={img.img}
                  alt=""
                  className="rounded-[15%] h-[300px] w-[100%] "
                />
                <div className="px-6 pt-2">
                  <h3 className="font-bold text-[#20B993] ">{img.name}</h3>
                </div>
                <p className="px-6 py-2">{img.Portfolio}</p>
              </div>
              // </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
